import React from "react"
import { Container, Row, Col, Image, Button } from "react-bootstrap"
import HabResearch1 from "../../../images/research/hab_research_1.png"
import HabResearch2 from "../../../images/research/hab_research_2.png"
import HabResearch3 from "../../../images/research/hab_research_3.png"
import HabResearch5 from "../../../images/research/hab_research_5.png"
import HabResearch6 from "../../../images/research/hab_research_6.png"
import TwinMotion1 from "../../../images/research/twinMotion1.png"
import TwinMotion2 from "../../../images/research/twinMotion2.png"
import GH1 from "../../../images/research/GH1.png"
import HabResearchGif from "../../../images/research/researchGif.gif"
import PavilionProperties from "../../../images/research/MetadataDiagram.png"
import MassingInit from "../../../images/research/massingInit.png"
import MassingEnd from "../../../images/research/massingEnd.png"
import shopLogo from "../../../images/logo.png"
import cobaLogo from "../../../images/cobaLogo.png"
import HabitatsTraitBreakdown from "../../../assets/HabitatsTraitBreakdown.pdf"

const styles = require("./research.module.scss");

type ResearchProps = {}

const Research: React.FC<ResearchProps> = ({}) => {
  return (
    <>
      <Container className={styles.introContainer}>
        <Row>
          <Col className="col-12 col-md-9">
            <p className={styles.introText}>
              <span className={styles.highlightedText}>Habitats</span> is just SHoP's first exploration into the Metaverse. SHoP plans to continue dedicating 
              resources to researching web3 and the influences that blockchain technologies can have on architecture, design, and real estate - writing code that pushes what is currently possible in the Metaverse.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className={styles.contentContainer}>
        <Row className={styles.habitatsResearchImagesContainer}>
          <Col>
            <Image className={styles.moduleImage} src={TwinMotion2} />
          </Col>
          <Col className={styles.secondTwinMotion}>
            <Image className={styles.moduleImage} src={TwinMotion1} />
          </Col>
        </Row>

        <Row>
          <Col className="col-12 col-sm-3">
            <div className={styles.dash} />
          </Col>
        </Row>

        <Row>
          <Col>
            <p className={styles.descriptionText}>
              The blockchain ecosystem, including Non-Fungible Tokens (NFTs) as well as the decentralization of Metaverses show
              expanding opportunities. Habitats is the product of aligning two separate efforts within SHoP research, generative/algorithmic
              design and virtual reality software development.
            </p>
          </Col>
        </Row>
        <Row className={` ${styles.moduleRow} d-flex justify-content-start`}>
          <Col className={`${styles.moduleColContainer} col-12 col-md-3`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={HabResearchGif} />
          </Col>
          <Col className={`${styles.moduleColContainer} col-12 col-md-3`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={GH1} />
          </Col>
          <Col className={`${styles.moduleColContainer} d-flex flex-column col-12 col-md-3`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
              As generative design methods become more popular within the architectural design process, it is important to measure both quantitative and
              qualitative output. Generative design lends itself well to measuring the quantitative output but the qualitative (user experience) output
              is more difficult to measure.
            </p>
          </Col>
        </Row>
        <Row className={`${styles.moduleRow} ${styles.reverseRowOnMobile}`}>
          <Col className={`${styles.moduleColContainerEnd} col-12 col-md-3`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
              SHoP thinks the metaverse can be used as a proving ground, to get feedback and user data in order to influence and improve our
              designs in the real world.
            </p>
            <p className={styles.descriptionTextSmall}>
              Habitats represents a general effort to explore and develop within the many Metaverses. This does not speak to any one
              specific project but the sub-brand dedicated to Metaverse research.
            </p>
          </Col>
          <Col className={`${styles.moduleColContainer} col-12 col-md-4`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={HabResearch2} />
          </Col>
          <Col className={`${styles.moduleColContainer} col-12 col-md-4`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={HabResearch3} />
          </Col>
        </Row>
        <Row className={` ${styles.moduleRow} d-flex justify-content-start`}>
          <Col className={`${styles.moduleColContainer} col-12 col-md-4`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={HabResearch5} />
          </Col>
          <Col className={`${styles.moduleColContainer} col-12 col-md-4`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={HabResearch1} />
          </Col>
          <Col className={`${styles.moduleColContainer} d-flex flex-column col-12 col-md-3`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
              The feature sets and decorator sets shown here, in combination with a machine learning based massing model, are fed into
              the algorithm. This generates an envelope that is based on previous SHoP design methods.
            </p>
          </Col>
        </Row>
        <Row className={`${styles.moduleRow} ${styles.reverseRowOnMobile}`}>
          <Col className={`${styles.moduleColContainerEnd} col-12 col-md-3`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
              SHoP is committed to sustainable solutions and is monitoring the progress of various
              L2s and the developments in PoS to consider transitioning to once the technology and
              infrastructure have advanced to meet the needs of our vision.  
            </p>
          </Col>
          <Col className='col-0 col-md-2'/>
          <Col className={`${styles.moduleColContainer} col-12 col-md-4`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={HabResearch6} />
          </Col>
          <Col className='col-0 col-md-2'/>
        </Row>
        <Row className={`${styles.moduleRow} ${styles.reverseRowOnMobile}`}>
          <Col className={`${styles.moduleColContainerEnd} col-12 col-md-3`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
              Each Habitat is procedurally generated using a combination of machine learning and specified aesthetic traits.   
            </p>
            <a href={HabitatsTraitBreakdown} target='_blank' style={{ width: '100%' }}>
              <Button
                variant='outline-primary'
                className={styles.downloadButton}
              >
                VIEW TRAIT BREAKDOWN
              </Button>
            </a>
          </Col>
          <Col className='col-0 col-md-2'/>
          <Col className={`${styles.moduleColContainerCenter} col-12`}>
            <Image className={styles.diagram} src={PavilionProperties} />
            <Image className={styles.diagramMobile} src={PavilionProperties} />
          </Col>
        </Row>
        <Row className={` ${styles.moduleRow} d-flex justify-content-start`}>
          <Col className={`${styles.moduleColContainer} col-12 col-md-6`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond} ${styles.topResearchImage}`} src={MassingInit} />
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond} ${styles.bottomResearchImage}`} src={MassingEnd} />
          </Col>
          <Col className={`${styles.moduleColContainer} d-flex flex-column col-12 col-md-3`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
              Our massing algorithm learned from a set of manually generated designs in order to
              create 2500 unique massings. The code then automatically analyzes these to create
              opportunities for vertical circulation and decorators.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-md-3">
            <div className={styles.dashSectionBreak} />
          </Col>
        </Row>
        <Row>
          <Col>
           <p className={styles.descriptionText}>
              Team
            </p>
          </Col>
        </Row>
        <Row className={` ${styles.moduleRow} d-flex justify-content-start`} id='team'>
         <Col className={`${styles.logoContainer} col-12 col-md-4`}>
            <a href="https://www.shoparc.com/work/" className={styles.logoLink} target="_blank">
              <Image className={`${styles.logoImage}`} src={shopLogo} />
            </a>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <a href="https://www.shoparc.com/work/" className={styles.logoLink} target="_blank">
              <p className={styles.descriptionTeamText}>
                <span className={styles.highlightedText}>SHoP Architects</span> is a technology focused architecture firm based in NYC, NY. SHoP has had the privilege to work with incredible clients on projects such as the YouTube HQ, Uber HQ, Barclays Arena, and super tall towers around the world. SHoP heavily integrates the use of data and generative algorithms in their design process. 
              </p>
            </a>
          </Col>
        </Row>
        <Row className={` ${styles.moduleRow} d-flex justify-content-start`}>
         <Col className={`${styles.logoContainer} col-12 col-md-4`}>
            <a href="http://cobadot.com/"  className={styles.logoLink} target="_blank">
              <Image className={`${styles.logoImage}`} src={cobaLogo} style={{ borderRadius: "0px" }} />
            </a>
          </Col>
          <Col >
            <a href="http://cobadot.com/"  className={styles.logoLink} target="_blank">
              <p className={styles.descriptionTeamText}>
                <span className={styles.highlightedText}>coba</span> creates accessible tools for newly minted web3 creators. The team is always looking to push what is possible while still supporting artists' creative visions as they make their first steps into crypto.
              </p>
            </a>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Research
