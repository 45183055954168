import React from "react"
import { PageProps } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Research from "../../components/pages/research"

const ResearchPage: React.FC<PageProps> = pageProps => {
  return (
    <Layout pageProps={pageProps}>
      <SEO title="Habitats | Research" />
      <Research />
    </Layout>
  )
}

export default ResearchPage
